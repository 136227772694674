import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

class UserFundModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      userData: [],
      dataStatus: false,
      portfolioUsdt: 0,
      portfolioInr: 0,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.currentRecord", nextProps.user);
    if (nextProps.currentRecord) {
      this.setState({
        userData: nextProps.currentRecord.data,
        dataStatus: nextProps.currentRecord.status,
      });
    }
    if (nextProps.user) {
      this.setState({
        id: nextProps.user.id,
      });
    }
    if (nextProps.portfolioRecord) {
      this.setState({
        portfolioUsdt: nextProps.portfolioRecord[0].USDT,
        portfolioInr: nextProps.portfolioRecord[0].INR,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  tableDataHtml = () => {
    if (this.state.dataStatus == true) {
      const html = [];
      const classThis = this;
      this.state.userData.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>{value.name}</td>
            <td>
              {value.getValue.main[0].coin_amount != null
                ? value.getValue.main[0].coin_amount
                : 0}
            </td>
            <td>
              {value.getValue.spot[0].coin_amount != null
                ? value.getValue.spot[0].coin_amount
                : 0}
            </td>
            <td>
              {value.wallet_address != null
                ? value.wallet_address
                : ""}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-fund-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">User Portfolio</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="portfolio-outer d-flex flex-wrap justify-content-between">
                  {/* <h6>Portfolio (INR): {this.state.portfolioInr}</h6> */}
                  <h6>Portfolio (USDT): {this.state.portfolioUsdt}</h6>
                </div>
                <div className="mb-3 text-right">
                {console.log("window.location.href",window.location.pathname)}
                  {window.location.pathname === "/users" && (
                    <>
                      <button
                        className="btn btn-success mr-2"
                        data-toggle="modal"
                        data-target="#update-userbuy-modal"
                      >
                        Buy
                      </button>
                      <button
                        className="btn btn-danger mr-2"
                        data-toggle="modal"
                        data-target="#update-usersell-modal"
                      >
                        Sell
                      </button>
                    </>
                  )}
                  <button
                    className="btn btn-primary mr-2"
                    data-toggle="modal"
                    data-target="#update-userrefer-modal"
                  >
                    Refer
                  </button>
                  <button
                    className="btn btn-primary mr-2"
                    data-toggle="modal"
                    data-target="#update-userdeposite-modal"
                  >
                    Deposit
                  </button>
                  <button
                    className="btn btn-primary mr-2"
                    data-toggle="modal"
                    data-target="#update-userwithdrawal-modal"
                  >
                    Withdrawal
                  </button>
                  {/* <button
                    className="btn btn-primary mr-2"
                    data-toggle="modal"
                    data-target="#update-transfer-modal"
                  >
                    Transfer
                  </button> */}
                </div>
                <div className="portfolio-popup-outer d-flex flex-wrap justify-content-between align-items-center"></div>
                <div className=" modal_scrool">
                <table className="table w-100 ">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Currency</th>
                      <th>Main Wallet</th>
                      <th>Spot Wallet</th>
                      <th>Wallet Address</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserFundModal.propTypes = {
  // userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UserFundModal));
